document.addEventListener("turbolinks:load", function() {
    let currentLocation = window.location.pathname.replace(/\/$/, '');
    let navItems = document.querySelectorAll(".nav-item");
    
    navItems.forEach(function(item) {
        let link = item.querySelector(".nav-link");
        if (link) {
            if (currentLocation == link.getAttribute("href")) {
                item.classList.add("active");
                link.classList.add("active");

                let parentMultiLevel = item.closest('.multi-level');
                if (parentMultiLevel) {
                    parentMultiLevel.classList.add('show');
                }
            }
        }
    });
});